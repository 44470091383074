export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://kloe67lgmg.execute-api.us-west-2.amazonaws.com/dev",
    APIURL: "https://ml56ic2cbb.execute-api.us-west-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_XZ9Qev8qQ",
    APP_CLIENT_ID: "1oeu9njjdd4udtahbhbo2up9uh",
    IDENTITY_POOL_ID: "us-west-2:28fbfc8f-3af5-495a-b93b-e3e7af3c2146"
  }
};
